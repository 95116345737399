<template>
  <loader-view v-if="loading" />

  <v-container v-else>
    <div class="d-flex align-center mb-4">
      <v-btn class="mr-2 rounded-lg" icon @click="close()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <h1 class="text-h5">
        <span class="work-sans">
          Detalhes do contrato: {{ contract.nomeContrato }}
        </span>
      </h1>
    </div>

    <v-row>
      <!--       <v-col cols="12">
        <v-card class="shadow rounded-lg" color="backgorund">
          <div class="d-flex align-center px-4 py-2">
            <div class="text-h6 overtext--text">
              <span class="work-sans">{{ contract.nomeContrato }} </span>
            </div>

            <v-spacer />

             <v-btn color="primary" depressed rounded small dark>
              <v-icon small left> mdi-pencil </v-icon>
              Editar
            </v-btn>
          </div>

          <div class="d-flex flex-column px-4 py-2">
            <div v-for="(item, i) in details" class="d-flex mb-1" :key="i">
              <span class="mr-2 text-body-1 subtext--text">
                <v-icon>mdi-circle-small</v-icon>
                {{ item.label }}
              </span>

              <span class="text-body-1">{{ item.value }}</span>
            </div>
          </div>
        </v-card>
      </v-col> -->

      <v-col cols="12">
        <IncomeCalendar />
      </v-col>

      <v-col cols="12" md="6">
        <RecordsCard />
      </v-col>

      <v-col cols="12" md="6">
        <CustomersContracts />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { displayAlert, currencyMask } from "@/utils";
import IncomeCalendar from "@/components/contractDetails/IncomeCalendar";
import CustomersContracts from "@/components/contractDetails/CustomersContracts";
import RecordsCard from "@/components/contractDetails/RecordsCard";

export default {
  name: "ContractsDetails",

  data() {
    return {
      loading: true,
      contract: null,
    };
  },

  beforeMount() {
    this.getData();
  },

  components: {
    IncomeCalendar,
    CustomersContracts,
    RecordsCard,
  },

  computed: {
    details() {
      return [
        {
          label: "Desc de rendimento:",
          value: this.contract.descRendimentoContrato,
        },
        {
          label: "Tempo de contrato:",
          value: this.contract.diasRetido + " dias",
        },
        {
          label: "Valor mínimo:",
          value: "R$ " + currencyMask(this.contract.minDeposito),
        },
        {
          label: "Valor máximo:",
          value: "R$ " + currencyMask(this.contract.maxDeposito),
        },
        {
          label: "Objetivo:",
          value: this.contract.objetivo,
        },
        {
          label: "Beneficios:",
          value: this.contract.listaBeneficios.toString(),
        },
      ];
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "listContratos",
        };

        await request(payload).then((res) => {
          res.contratos.map((e) => {
            if (e.id === this.$route.params.id) this.contract = e;
          });
        });
      } catch (err) {
        this.displayAlert(err, 1);
        this.close();
      } finally {
        this.loading = false;
      }
    },

    close() {
      const callback = this.$route.query.callback;

      if (callback) this.$router.push({ path: `/${callback}` });
      else this.$router.push({ path: "/contracts" });
    },

    displayAlert,
  },
};
</script>
