<template>
  <v-card class="shadow rounded-lg" color="backgorund">
    <div class="d-flex align-center px-4 py-2">
      <div class="text-h6 overtext--text">
        <span class="work-sans">Registros de rendimento</span>
      </div>

      <v-spacer />

      <v-btn
        color="primary"
        depressed
        rounded
        small
        dark
        @click="recordDialog = true"
      >
        <v-icon small left> mdi-plus </v-icon>
        Adicionar
      </v-btn>
    </div>

    <loader-content v-if="loading" />

    <section v-else class="pb-6">
      <v-data-table
        :headers="headers"
        :items="income"
        class="mb-4 transparent"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <template v-slot:[`item.descricao`]="{ item }">
          <span v-if="item.descricao">
            {{ item.descricao }}
          </span>

          <br v-if="item.descricao" />

          <span class="subtext--text text-caption">
            {{ item.data }}
          </span>
        </template>

        <!-- value  -->
        <template v-slot:[`item.valor`]="{ item }">
          {{ item.valor }}%
        </template>
      </v-data-table>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getData()"
      />
    </section>

    <v-dialog v-model="recordDialog" persistent max-width="500">
      <AddIncome
        v-if="recordDialog"
        @close="recordDialog = false"
        @success="getData()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { request } from "@/services";
import { handlePagination, currencyMask } from "@/utils";
import AddIncome from "./AddIncome.vue";

export default {
  data() {
    return {
      loading: true,
      recordDialog: false,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Registro", value: "descricao" },
        { text: "Valor", value: "valor" },
      ],
      income: [],
    };
  },

  components: {
    AddIncome,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "getRendimentoByContrato",
          contratoID: this.$route.params.id,
          page: this.pagination.page - 1,
        };

        await request(payload).then(async (res) => {
          this.income = res.contratos;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
