<template>
  <v-card color="surface">
    <v-card-title>
      Editar rendimento

      <v-spacer />

      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="px-6 pb-4">
      <v-list class="pa-0 mb-6" nav>
        <v-list-item class="accent">
          <v-list-item-subtitle> Data aplicada </v-list-item-subtitle>

          <v-list-item-title class="text-right">
            {{ income.data }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="accent">
          <v-list-item-subtitle> Data do rendimento </v-list-item-subtitle>

          <v-list-item-title class="text-right">
            {{ income.dataRendimento }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="accent">
          <v-list-item-subtitle> Valor atual </v-list-item-subtitle>

          <v-list-item-title class="text-right">
            {{ income.valor }}%
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-form v-model="valid" ref="form" autocomplete="off">
        <div class="mb-1">Alterar porcentagem do rendimento</div>

        <v-text-field
          v-model.number="form.value"
          background-color="accent"
          type="number"
          :rules="rules"
          solo
          flat
        />
      </v-form>

      <v-list-item class="px-0">
        <v-list-item-subtitle> Excluir rendimento? </v-list-item-subtitle>

        <v-list-item-action class="pr-4">
          <v-btn color="red" dark depressed small @click="deleteDialog = true">
            Excluir
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </section>

    <v-card-actions class="accent">
      <v-spacer />

      <v-btn class="mr-2" plain @click="close()"> Cancelar </v-btn>

      <v-btn color="primary" @click="submit()"> Confirmar </v-btn>
    </v-card-actions>

    <!-- dialogs -->
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <DeleteIncome
        v-if="deleteDialog"
        :income="income"
        @close="deleteDialog = false"
        @success="$emit('success'), close()"
      />
    </v-dialog>

    <!-- loader -->
    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";
import DeleteIncome from "./DeleteIncome.vue";

export default {
  data() {
    return {
      loading: false,
      deleteDialog: false,
      valid: false,
      form: {
        value: 0,
      },
    };
  },

  props: {
    income: {
      type: Object,
      default: {},
    },
  },

  components: {
    DeleteIncome,
  },

  beforeMount() {
    this.form.value = Number(this.income.valor);
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "editCalendarioRendimento",
          idRendimento: this.income.id,
          valor: this.form.value,
        };

        await request(payload).then(async (res) => {
          this.displayAlert(res.message);
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style>
</style>