<template>
  <loader-content v-if="loading" />

  <div v-else>
    <v-card class="shadow rounded-lg mb-4" color="backgorund">
      <div class="d-flex align-center px-4 py-2">
        <div class="text-h6 overtext--text">
          <span class="work-sans">Calendário de Rendimentos</span>
        </div>

        <v-spacer />

        <v-btn
          color="primary"
          depressed
          rounded
          small
          dark
          @click="addDialog = true"
        >
          <v-icon small left> mdi-plus </v-icon>
          Adicionar
        </v-btn>
      </div>

      <!-- calendar -->
      <section>
        <div class="d-flex align-center justify-center mb-4">
          <v-btn color="primary" icon @click="prev()">
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>

          <span class="text-body-1 mx-2">
            {{ currentMonth }}
          </span>

          <v-btn color="primary" icon @click="next()">
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <div class="px-4 pb-4">
          <v-calendar
            v-model="event"
            ref="calendar"
            class="rounded-lg"
            color="primary"
            type="month"
            :events="events"
          />
        </div>
      </section>
    </v-card>

    <v-row justify="end">
      <v-col v-for="(item, i) in analytics" cols="12" sm="6" md="4" :key="i">
        <v-card class="shadow rounded-lg" color="background">
          <v-list-item>
            <v-list-item-avatar
              class="opacity-primary"
              :size="$vuetify.breakpoint.smAndDown ? 32 : 42"
            >
              <v-icon color="primary" :small="$vuetify.breakpoint.smAndDown">
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="text-body-1 text-md-h6 font-weight-regular"
              >
                {{ item.value }}
              </v-list-item-title>

              <v-list-item-subtitle class="text-caption text-md-body-2">
                {{ item.label }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addDialog" persistent max-width="500">
      <AddCalendarIncome
        v-if="addDialog"
        @close="addDialog = false"
        @success="getData()"
      />
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="500">
      <EditIncome
        v-if="editDialog"
        :income="selected"
        @close="editDialog = false"
        @success="getData()"
      />
    </v-dialog>
  </div>
</template>

<script>
import { request } from "@/services";
import { formatAmount, currencyMask } from "@/utils";
import AddCalendarIncome from "./AddCalendarIncome.vue";
import EditIncome from "./EditIncome.vue";
const moment = require("moment");

export default {
  data() {
    return {
      loading: true,
      addDialog: false,
      editDialog: false,
      events: [],
      event: "",
      sum: 0,
      selected: null,
      range: {
        initialDate: null,
        finalDate: null,
      },
      totalDeposit: 0,
      projection: 0,
    };
  },

  components: {
    AddCalendarIncome,
    EditIncome,
  },

  created() {
    this.range = {
      initialDate: moment().format("YYYY-MM-01"),
      finalDate: moment().format("YYYY-MM-") + moment().daysInMonth(),
    };
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    event(value) {
      this.events.map((e) => {
        if (e.start === value) {
          this.selected = e.data;
          this.editDialog = true;
        }
      });
    },
  },

  computed: {
    currentMonth() {
      return moment(this.range.initialDate).locale("pt-br").format("MMMM YYYY");
    },

    analytics() {
      return [
        {
          icon: "mdi-currency-usd",
          label: "Total depositado",
          value: "R$ " + currencyMask(this.totalDeposit),
        },
        {
          icon: "mdi-calendar-month",
          label: "Rendimento no período",
          value: this.sum + "%",
        },
        {
          icon: "mdi-trending-up",
          label: "Projeção de Rendimento",
          value: "R$ " + currencyMask(this.projection),
        },
      ];
    },
  },

  methods: {
    async getData() {
      try {
        this.events = [];

        const payload = {
          method: "listaCalendarioRendimento",
          contratoID: this.$route.params.id,
          dataInicial: this.range.initialDate,
          dataFinal: this.range.finalDate,
        };

        await request(payload).then(async (res) => {
          this.totalDeposit = res.totalDepositadoContrato;
          this.projection = res.projecaoRendimento;

          let sum = 0;

          res.datasAdd.map((e) => {
            this.events.push({
              name: `+${e.valor}%`,
              start: e.data,
              color: e.foiPago === "1" ? "green" : "blue",
              data: e,
            });

            sum = sum + Number(e.valor);
          });

          this.sum = formatAmount(sum, 2);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async prev() {
      this.loading = true;

      this.range = {
        initialDate: moment(this.range.initialDate)
          .subtract(1, "M")
          .format("YYYY-MM-DD"),

        finalDate: moment(this.range.finalDate)
          .subtract(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };

      await this.getData();

      this.$refs.calendar.prev();
    },

    async next() {
      this.loading = true;

      this.range = {
        initialDate: moment(this.range.initialDate)
          .add(1, "M")
          .format("YYYY-MM-DD"),

        finalDate: moment(this.range.finalDate)
          .add(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };

      await this.getData();

      this.$refs.calendar.next();
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped></style>
