<template>
  <v-card>
    <div class="pa-6">
      <div class="d-flex mb-4">
        <span class="text-h6">Adicionar rendimento manual</span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <custom-input
          v-model="form.value"
          ref="value"
          label="Porcentagem de rendimento*"
          placeholder="Porcentagem de rendimento"
          @keypress="onlyNumber($event)"
        />

        <custom-text-area
          v-model="form.description"
          ref="description"
          label="Descrição do rendimento*"
          placeholder="Descrição do rendimento"
        />
      </v-form>

      <div class="d-flex justify-end">
        <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

        <v-btn color="primary" class="text-none" depressed @click="submit()">
          Confirmar
        </v-btn>
      </div>
    </div>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { request } from "@/services";
import { displayAlert, onlyNumber } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      form: {
        value: 0,
        description: "",
      },
    };
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "addRendimento",
          contratoID: this.$route.params.id,
          valor: this.form.value,
          descricao: this.form.description,
        };

        await request(payload).then(async (res) => {
          this.displayAlert(res.message);
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,

    onlyNumber
  },
};
</script>

<style></style>
