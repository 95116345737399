<template>
  <v-card>
    <div class="pa-6">
      <div class="d-flex mb-4">
        <span class="text-h6">Adicionar rendimento por período</span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <custom-input
          v-model="form.value"
          ref="value"
          label="Porcentagem de rendimento*"
          placeholder="Porcentagem de rendimento"
          @keypress="onlyNumber($event)"
        />

        <div class="text-body-2 mb-1">
          <span class="work-sans"
            >Selecione o periodo de distribuição do rendimento</span
          >
        </div>

        <v-card class="mb-2" outlined>
          <v-date-picker
            v-model="form.dates"
            width="100%"
            color="primary"
            no-title
            range
          />
        </v-card>

        <v-text-field
          v-model="dateRange"
          append-icon="mdi-calendar"
          background-color="accent"
          :rules="calendarRules"
          readonly
          solo
          flat
        />
      </v-form>

      <div class="d-flex justify-end">
        <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

        <v-btn color="primary" class="text-none" depressed @click="submit()">
          Confirmar
        </v-btn>
      </div>
    </div>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { request } from "@/services";
import { displayAlert, onlyNumber } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      form: {
        value: 0,
        dates: [],
      },
    };
  },

  watch: {
    ["form.dates"](value) {
      if (value.length > 1) {
        if (value[0] > value[1]) {
          const temp = value[0];

          this.form.dates.splice(0, 1);
          this.form.dates.push(temp);
        }
      }
    },
  },

  computed: {
    dateRange() {
      return this.form.dates.join(" ~ ");
    },

    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },

    calendarRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => v.length > 1 || "Período inválido",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "geraCalendarioRendimento",
          contratoID: this.$route.params.id,
          valor: this.form.value,
          dataInicial: this.form.dates[0],
          dataFinal: this.form.dates[1],
        };

        await request(payload).then(async (res) => {
          this.displayAlert(res.message);
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,

    onlyNumber,
  },
};
</script>

<style></style>
