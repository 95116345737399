<template>
  <v-card class="shadow rounded-lg" color="backgorund">
    <div class="d-flex align-center px-4 py-2">
      <div class="text-h6 overtext--text">
        <span class="work-sans">Contratos</span>
      </div>
    </div>

    <loader-content v-if="loading" />

    <section v-else class="pb-6">
      <v-data-table
        :headers="headers"
        :items="users"
        class="mb-4 transparent"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <!-- user -->
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="`/user/${item.userID || item.id}`">
            <v-chip color="primary" link>
              <v-icon left small>mdi-account</v-icon>

              <span>{{ item.name || item.nome }}</span>
            </v-chip>
          </router-link>
        </template>

        <!-- balance  -->
        <template v-slot:[`item.saldo`]="{ item }">
          R$ {{ currencyMask(item.saldo) }}
        </template>
      </v-data-table>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getData()"
      />
    </section>
  </v-card>
</template>

<script>
import { request } from "@/services";
import { handlePagination, currencyMask } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Saldo", value: "saldo" },
      ],
      users: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        let payload = {
          method: "listUsersWithContrato",
          page: this.pagination.page - 1,
        };

        if (this.$route.params.id) {
          payload = {
            ...payload,
            contratoID: this.$route.params.id,
          };
        }

        await request(payload).then(async (res) => {
          this.users = res.usuarios;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
