<template>
  <v-card color="surface">
    <v-card-title>
      Excluir rendimento

      <v-spacer />

      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text> Deseja realmente excluir este rendimento? </v-card-text>

    <v-card-actions class="accent">
      <v-spacer />

      <v-btn class="mr-2" plain @click="close()"> Cancelar </v-btn>

      <v-btn color="red" dark @click="submit()"> Confirmar </v-btn>
    </v-card-actions>

    <!-- loader -->
    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
    };
  },

  props: {
    income: {
      type: Object,
      default: {},
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          method: "deleteCalendarioRendimento",
          idRendimento: this.income.id,
          valor: this.income.valor
        };

        await request(payload).then(async (res) => {
          this.displayAlert(res.message);
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style>
</style>